<template>
  <div
    class="pa-3 text-center"
  >
    Comments
  </div>
</template>
<script>
export default {
  name: 'ItemDialogComments'
}
</script>